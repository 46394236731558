/* OrderStatus Select, Vue Component */
<template>
    <v-chip :color="ChipColor(status) + ' v-chip-light-bg'" outlined small>{{ status }}</v-chip>
</template>

<script>
export default {
    props: {
        status: {
            type: String,
            required: true
        }
    },
    methods: {
        ChipColor(status) {
            let s = status.replace(' ', '').toLowerCase();
            if (s == "paid") {
                return "success";
            } else if (s == "open") {
                return "error";
            } else if (s == "refunded" || s == "replaced") {
                return "secondary";
            } else {
                return "warning";
            }
        }
    }
}
</script>