/* Invoice View, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="invoice-view" class="entity-view">
    <v-card-title>View Invoice</v-card-title>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col class="d-flex align-center flex-wrap pb-0">

          <Can I="update" on="CustomerCare">
            <v-btn color="primary" class="me-3" :to="{ name: 'invoice-edit', params: { id: entity.Id } }">
              <v-icon size="18" class="me-1">
                {{ icons.mdiPencilOutline }}
              </v-icon>
              <span>Edit</span>
            </v-btn>
          </Can>

          <Can I="delete" on="CustomerCare">
            <v-btn color="primary" class="me-3" @click="Delete">
              <v-icon size="18" class="me-1">
                {{ icons.mdiClose }}
              </v-icon>
              <span>Delete</span>
            </v-btn>
          </Can>

          <template v-if="entity && !entity.IsPaid">
            <Can I="update" on="CustomerCare">
              <action-confirm-dialog @confirmed="CreditOutstanding" openButtonText="Credit Outstanding" :dialogTitle="`Credit Outstanding`">
                <template v-slot:information>
                  <p>This will credit the customer with any outstanding balance on this invoice and mark it as payed.</p>
                </template>
              </action-confirm-dialog>
            </Can>
          </template>

          <template v-if="entity && entity.IsPaid && !entity.ReplacedId && !entity.RefundedDate">
            <Can I="update" on="CustomerCare">
              <action-confirm-dialog @confirmed="Refund" openButtonText="Refund Invoice" dialogTitle="Refund Invoice" commentTitle="CityPay Confirmation Code" commentPlaceholder="Cut and paste the CityPay confirmation of this refund before confriming.">
                <template v-slot:information>
                  <p>A refund <strong>must be issued manually</strong> through the <strong>CityPay control panel</strong> first.</p>
                  <p>This will fully refund the paid amount of this invoice but not any credited amount.</p>
                </template>
                <template v-slot:fields>
                  <v-text-field label="Refund Amount"
                                placeholder="Leave blank to refund entire amount payed"
                                prefix="£"
                                type="number"
                                flat
                                outlined
                                v-model="refundAmount"
                                :rules="[]"></v-text-field>
                </template>
              </action-confirm-dialog>
            </Can>
          </template>

        </v-col>
        <v-col class="text-right">

          <!-- <v-btn color="primary" class="me-3" @click="TestPayment">
            <span>Test Payment</span>
          </v-btn> -->

          <v-btn color="secondary" class="me-3" @click="Back">
            <v-icon size="18" class="me-1">{{ icons.mdiChevronLeft }}</v-icon>
            <span>Back</span>
          </v-btn>

        </v-col>
      </v-row>
    </v-card-text>

    <v-simple-table>
      <thead>
        <tr>
          <th colspan="2">Details</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>Reference</td>
          <td>{{ entity.Reference }}</td>
        </tr>

        <tr>
          <td>Order Reference</td>
          <td>
            <router-link class="font-weight-medium text-decoration-none"
                         :to="{ name: 'order-view', params: { id: entity.OrderId } }">
              {{ entity.OrderId_Reference }}
            </router-link>
          </td>
        </tr>

        <tr>
          <td>Order Status</td>
          <td><order-status-chip v-if="entity.OrderId_StatusId_Name" :status="entity.OrderId_StatusId_Name"></order-status-chip></td>
        </tr>

        <tr>
          <td>Member Reference</td>
          <td>
            <router-link class="font-weight-medium text-decoration-none"
                         :to="{ name: 'member-view', params: { id: entity.OrderId_MemberId } }">
              {{ entity.OrderId_MemberId_Reference }}
            </router-link>
          </td>
        </tr>

        <tr>
          <td>Member Name</td>
          <td>{{ entity.OrderId_MemberId_FullName }}</td>
        </tr>

        <tr>
          <td>Member Email</td>
          <td>{{ entity.OrderId_MemberId_Email }}</td>
        </tr>

        <tr>
          <td>Product Name</td>
          <td>{{ entity.ProductId_Name }}</td>
        </tr>

        <tr>
          <td><strong>Product Price</strong></td>
          <td><strong>{{ Display.Money(entity.ProductId_Price) }}</strong></td>
        </tr>

        <tr>
          <td><strong>Bespoke Price</strong></td>
          <td><strong>{{ Display.Money(entity.BespokePrice) }}</strong></td>
        </tr>

        <tr>
          <td><strong>Quote Price</strong></td>
          <td><strong>{{ Display.Money(entity.QuotePrice) }}</strong></td>
        </tr>

        <template v-if="entity.QuoteBreakdownItems && entity.QuoteBreakdownItems.length > 0">
        <tr v-for="(item, itemIndex) in entity.QuoteBreakdownItems" :key="itemIndex">
          <td>{{ item.Key }}</td>
          <td>{{ Display.Money(item.Value) }}</td>
        </tr>
        </template>

        <tr v-if="entity.QuoteNarrative">
          <td>Quote Narrative</td>
          <td>{{ (entity.QuoteNarrative) }}</td>
        </tr>

        <tr>
          <td>Heavy Item</td>
          <td>
            <template v-if="entity.HeavyItem">Yes</template>
            <template v-else>No</template>
          </td>
        </tr>

        <tr>
          <td>Custom Dimensions</td>
          <td>
            <template v-if="entity.ProductId_Length && entity.ProductId_Width && entity.ProductId_Height">
              {{ entity.ProductId_Length }}x{{ entity.ProductId_Width }}x{{ entity.ProductId_Height }}mm
            </template>
          </td>
        </tr>

        <tr>
          <td>Status</td>
          <td><invoice-status-chip v-if="entity.Status" :status="entity.Status"></invoice-status-chip></td>
        </tr>

        <tr>
          <td>Replaced By Invoice</td>
          <td>
            <router-link v-if="entity.ReplacedId" class="font-weight-medium text-decoration-none"
                         :to="{ name: 'invoice-view', params: { id: entity.ReplacedId } }">
              {{ entity.ReplacedId_Reference }}
            </router-link>
          </td>
        </tr>

        <tr>
          <td><strong>Total Amount</strong></td>
          <td><strong>{{ Display.Money(entity.Amount) }}</strong></td>
        </tr>

        <tr>
          <td>Extras</td>
          <td>
            <ul v-if="entity.ExtrasNames && entity.ExtrasNames.length > 0">
              <li v-for="(extra, index) in entity.ExtrasNames" :key="index">{{ extra }}</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>Extras Total</td>
          <td>{{ Display.Money(entity.ExtrasPrice) }}</td>
        </tr>

        <tr>
          <td>Discount</td>
          <td>{{ Display.Money(entity.Discount) }}</td>
        </tr>

        <tr>
          <td>Discount Code</td>
          <td>{{ entity.DiscountCode }}</td>
        </tr>

        <tr>
          <td>Credit</td>
          <td>{{ Display.Money(entity.Credit) }}</td>
        </tr>

        <tr>
          <td>Description</td>
          <td>{{ entity.Description }}</td>
        </tr>

        <tr>
          <td>Created By</td>
          <td>{{ entity.CreatedBy_FullName }}</td>
        </tr>

        <tr>
          <td>Paid Date</td>
          <td>{{ Display.DisplayDate(entity.PaidDate) }}</td>
        </tr>

        <tr>
          <td>Paid Amount</td>
          <td>{{ Display.Money(entity.PaidAmount) }}</td>
        </tr>

        <tr>
          <td>Payment Info</td>
          <td>{{ entity.PaymentInfo }}</td>
        </tr>

        <tr>
          <td>Refunded Date</td>
          <td>{{ Display.DisplayDate(entity.RefundedDate) }}</td>
        </tr>

        <tr>
          <td>Refunded Amount</td>
          <td>{{ Display.Money(entity.RefundedAmount) }}</td>
        </tr>

        <tr>
          <td>Created</td>
          <td>{{ Display.DateTime(entity.Created) }}</td>
        </tr>

        <tr>
          <td>Updated</td>
          <td>{{ Display.DateTime(entity.Updated) }}</td>
        </tr>

      </tbody>
    </v-simple-table>

  </v-card>
</template>

<script>
  import store from '@/store'
  import { useRouter } from '@core/utils'
  import * as Display from '@/datahelpers/displayTypes'
  import { Can } from '@casl/vue'
  import OrderStatusChip from '@/views/orderstatus/OrderStatusChip'
  import ActionConfirmDialog from '@/views/helpers/ActionConfirmDialog'
  import InvoiceStatusChip from '@/views/invoice/InvoiceStatusChip'

  import {
    mdiPencilOutline,
    mdiChevronLeft,
    // mdiPlus,
    // mdiViewList,
    // mdiClose
  } from '@mdi/js'

  import { ref, watch, computed, getCurrentInstance } from '@vue/composition-api'

  export default {
    components: {
      Can,
      OrderStatusChip,
      ActionConfirmDialog,
      InvoiceStatusChip
    },
    setup() {

      const vm = getCurrentInstance().proxy
      const { route, router } = useRouter();

      const entity = computed(() => {
        return store.state.app.Invoice;
      });
      const loading = ref(false);

      const fetchData = (id) => {
        store
          .dispatch('app/fetchInvoice', {
            id: id
          })
          .then(response => {
            loading.value = false
          })
          .catch(error => {
            console.log(error);
            router.push({ name: 'invoice-list' });
          })
      }

      // fetch the user information when params change
      watch(() => route.value.params.id, (id) => fetchData(id));

      // and call now
      fetchData(route.value.params.id);

      const Back = () => {
        router.go(-1);
      };

      const CreditOutstanding = (comment) => {

        store.dispatch('app/creditOutstandingInvoice', {
          id: entity.value.Id,
          entity: entity.value,
          comment: comment
        })
          .then(response => {
            router.push({ name: 'order-view', params: { id: entity.value.OrderId }, hash: '#Billing' });
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const refundAmount = ref(null);
      const Refund = (comment) => {

        store.dispatch('app/refundInvoice', {
          id: entity.value.Id,
          entity: entity.value,
          comment: comment,
          refundAmount: refundAmount.value
        })
          .then(response => {
            router.push({ name: 'order-view', params: { id: entity.value.OrderId }, hash: '#Billing' });
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const Delete = () => {

        var orderId = entity.value.OrderId;
        var invoiceId = entity.value.Id;

        store.dispatch('app/deleteInvoice', {
          id: invoiceId,
          entity: entity.value
        })
          .then(response => {
            router.push({ name: 'order-view', params: { id: orderId } });
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const TestPayment = () => {
        var invoiceId = entity.value.Id;
        vm.$http
          .post(`${store.state.apiUrl}/public/payment/pay/${invoiceId}`)
          .then(response => {
            if (response.data.success) {
              console.log(response.data)
              if (response.data.redirect) {
                window.location.replace(response.data.url)
              }
            }
            else {
              // display errors
            }
          })
          .catch(ex => {
          })
          .finally(() => {
          })
      }

      return {
        Back,
        loading,
        entity,
        Display,
        CreditOutstanding,
        refundAmount,
        Refund,
        Delete,
        TestPayment,

        icons: {
          mdiPencilOutline,
          //mdiPlus,
          //mdiViewList,
          //mdiClose,
          mdiChevronLeft
        },
      };
    }
  }
</script>

<style lang="scss" scoped>
  .entity-view::v-deep .v-data-table th:last-child {
    width: 66%;
  }
</style>

